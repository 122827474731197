
import { Component, Watch, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Icon, Image, Button, Checkbox } from "vant";
import PrivacyDialog from "./Components/PrivacyDialog.vue";
import { wechatLogin } from "@/utils/weChatLogin";
import { isWechat } from "@/utils/index";

@Component({
  name: "login",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    PrivacyDialog,
  },
})
export default class Login extends Mixins(Mixin) {
  public activeIcon = require("@/assets/images/Public/check.png");
  public inactiveIcon = require("@/assets/images/Public/uncheck.png");
  public checked = false;
  public oauthMessage = "";
  public openPrivacy = false;

  @Watch("checked")
  checkedChange(): void {
    sessionStorage.setItem("checked", JSON.stringify(this.checked));
  }

  activated(): void {
    // 从上个页面返回时，如果选中，此页面也让选中
    this.checked = sessionStorage.getItem("checked") == "true" ? true : false;
  }

  /**
   * 是否是微信环境
   */
  isWechatEnv(): boolean {
    return isWechat();
  }
  goMynotice(name: string, type: string): void {
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }

  // 关闭登录页面
  closeLogin(): void {
    if (this.RouteRecordModule.beforeLoginPage) {
      this.$router.go(-1);
    } else {
      this.$router.replace("/home");
    }
  }

  oncheck(): void {
    this.checked = true;
    this.weChatLogin();
  }

  // 微信登录
  weChatLogin(): void {
    if (this.checked) {
      wechatLogin("loginPage");
    } else {
      this.openPrivacy = true;
    }
  }

  // 关闭对话框，状态重置
  closePricay(): void {
    this.openPrivacy = false;
  }
}
