
import { Component, Watch, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Icon,
  Image,
  Button,
  Checkbox,
  Form,
  Field,
  Dialog,
  Popup,
  Picker,
} from "vant";
import { isPhoneNum } from "@/utils/index";
import UserCheck from "./UserCheck.vue";
import { SlideCheck } from "@/types";
import { loginCommon } from "@/utils/index";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  name: "mobileLogin",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Form.name]: Form,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    UserCheck,
  },
})
export default class MobileLogin extends Mixins(Mixin) {
  public isFirstSend = true;
  public activeIcon = require("@/assets/images/Public/check.png");
  public inactiveIcon = require("@/assets/images/Public/uncheck.png");
  public loginDisabled = true;
  public reciveCode = "获取验证码";
  public hideshow = true; //显示或隐藏footer
  public docmHeight = document.documentElement.clientHeight; //默认屏幕高度
  public showHeight = document.documentElement.clientHeight; //实时屏幕高度
  public checked = false;
  public userCheckShow = false;
  public showPrivacy = false;
  public form = {
    phone: "",
    code: "",
    phoneCode: "",
    phoneArea: "86",
    token: "",
  };
  public showPicker = false;
  public phoneColumns: { text: string; key: string }[] = [];
  public columns = [
    {
      dictId: "5199110600000000003",
      dictClsId: "1099110500000000059",
      dictCode: "86",
      dictName: "中国大陆",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000004",
      dictClsId: "1099110500000000059",
      dictCode: "852",
      dictName: "中国香港",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000005",
      dictClsId: "1099110500000000059",
      dictCode: "853",
      dictName: "中国澳门",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000006",
      dictClsId: "1099110500000000059",
      dictCode: "886",
      dictName: "中国台湾",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
  ];
  public countdown = {
    num: 60,
    status: false,
  };
  public redirect = "/mine";
  public isUserCheck = false;
  public checkModel: SlideCheck | undefined = undefined;
  get isPhoneNum(): boolean {
    return isPhoneNum(this.form.phone, this.form.phoneArea);
  }
  @Watch("form.phoneCode")
  cloginDisabled(): void {
    if (this.form.phoneCode) {
      if (this.isPhoneNum && this.checked && this.form.phoneCode.length == 6) {
        this.loginDisabled = false;
      } else {
        this.loginDisabled = true;
      }
    } else {
      this.loginDisabled = true;
    }
  }
  @Watch("form.phone")
  changePhone(): void {
    this.isUserCheck = false;
    this.checkModel = undefined;
    if (this.form.phoneCode) {
      if (this.isPhoneNum && this.checked && this.form.phoneCode.length == 6) {
        this.loginDisabled = false;
      } else {
        this.loginDisabled = true;
      }
    } else {
      this.loginDisabled = true;
    }
  }

  @Watch("checked")
  checkedChange(): void {
    sessionStorage.setItem("checked", JSON.stringify(this.checked));
    if (this.form.phoneCode) {
      if (this.form.phoneCode.length == 6 && this.isPhoneNum && this.checked) {
        this.loginDisabled = false;
      } else {
        this.loginDisabled = true;
      }
    } else {
      this.loginDisabled = true;
    }
  }

  fixHeight(): void {
    // window.onresize监听页面高度的变化,防止底部图标上来
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
        if (this.docmHeight > this.showHeight) {
          this.hideshow = false;
        } else {
          this.hideshow = true;
        }
      })();
    };
  }

  activated(): void {
    //防止重复添加,状态置空
    this.phoneColumns = [];
    if (this.UserModule.isClearLoginCode) {
      this.form.phoneCode = "";
      this.UserModule.SET_isClearLoginCode_PERSIST(false);
    }
    //手机号区号
    this.columns.forEach((item) => {
      this.phoneColumns.push({
        text: `${item.dictName} +${item.dictCode}`,
        key: item.dictCode,
      });
    });
    this.checked = sessionStorage.getItem("checked") == "true" ? true : false;
    //上次登录存的手机号自动填充
    // this.form.phone = !localStorage.getItem("lastPhoneNum")
    //   ? ""
    //   : JSON.parse(JSON.stringify(localStorage.getItem("lastPhoneNum")));
    this.fixHeight();
  }
  //上次登录成功存的手机号自动填充
  created(): void {
    this.form.phone = !localStorage.getItem("lastPhoneNum")
      ? ""
      : JSON.parse(JSON.stringify(localStorage.getItem("lastPhoneNum")));
  }
  closeLogin(): void {
    this.$router.replace("/login");
  }
  goMynotice(name: string, type: string): void {
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
  /**
   * 打开滑块验证码
   */
  openUserCheck(): void {
    this.userCheckShow = true;
    this.$nextTick(() => {
      let userCheck = this.$refs["user-check"] as UserCheck;
      if (userCheck) {
        userCheck.resetData();
      }
    });
  }

  // 修改手机区号
  onConfirm(value: { key: string }): void {
    this.form.phoneArea = value.key;
    this.changePickerStatus();
  }
  // 手机区号选择器打开&关闭
  changePickerStatus(): void {
    this.showPicker = !this.showPicker;
  }
  // 手机号验证规则
  checkPhoneNum(): boolean {
    if (this.isPhoneNum) {
      return true;
    }
    if (!this.form.phone) {
      this.$toast("手机号输入不能为空");
      return false;
    }
    this.$toast("请输入正确的手机号，否则无法接收验证码");
    return false;
  }
  // 同意协议
  agreePrivacy(): void {
    this.checked = true;
    // 接着进行人机验证
    this.openUserCheck();
  }
  // 不同意协议
  disagreePrivacy(): void {
    this.userCheckShow = false;
  }
  // 直接关闭协议内容
  onCloseDialog(): void {
    this.showPrivacy = false;
    this.userCheckShow = false;
  }
  // 关闭人机验证
  onCloseUserCheck(): void {
    this.userCheckShow = false;
  }
  checkPhoneCode(): boolean {
    if (this.form.phoneCode) {
      if (this.form.phoneCode.length == 6) {
        return true;
      } else {
        this.$toast("请输入正确的验证码");
        return false;
      }
    } else {
      this.$toast("请输入正确的验证码");
      return false;
    }
  }
  // 人机校验对话框确定
  getUserToken(data: { token: string; sessionId: string; sig: string }): void {
    this.isUserCheck = true;
    this.isFirstSend = false;
    this.checkModel = data;
    //未调试接口前直接调用，接口调试后调用sendCodeMessage
    this.sendCodeMessage();
    this.isUserCheck = false;
  }
  // 发送短信验证码
  sendCodeMessage(): void {
    if (this.reciveCode == "再次获取") {
      this.form.phoneCode = "";
    }
    // 首先进行手机号校验
    if (!this.checkPhoneNum()) {
      return;
    }
    // 没有同意政策的话，弹出对话框提醒用户同意
    if (!this.checked) {
      this.showPrivacy = true;
      return;
    }
    if (this.isUserCheck) {
      if (this.checkPhoneNum()) {
        this.userCheckShow = false;
        let phoneCaptchaRequest = {
          account: this.form.phone,
          phoneArea: "+" + this.form.phoneArea,
          sessionId: (this.checkModel as SlideCheck).sessionId,
          sig: (this.checkModel as SlideCheck).sig,
          token: (this.checkModel as SlideCheck).token,
        };
        this.$api.memberApi.cuUser.getMemberCaptcha(
          phoneCaptchaRequest,
          (data) => {
            return data;
          }
        );
        if (!this.isFirstSend) {
          this.reciveCode = "再次获取";
        }
        this.$toast("验证码已发送，请注意查收！");
        monitorEvent("AccountLogin_Send", "点击发送验证码"); // 埋点：注册登录，点击发送验证码
        this.countDown();
      }
    } else {
      this.openUserCheck();
    }
  }
  // 验证码发送倒计时
  countDown(): void {
    if (this.countdown.num === 0) {
      this.countdown.status = false;
      this.countdown.num = 60;
      this.isUserCheck = false;
      return;
    }
    this.countdown.status = true;
    this.countdown.num--;

    setTimeout(() => {
      this.countDown();
    }, 1000);
  }
  // 登录
  submitLogin(): void {
    // 再次校验
    if (this.checkPhoneNum() && this.checkPhoneCode()) {
      monitorEvent("AccountLogin_ClickLogin", "点击登录"); // 埋点：注册登录，点击登录
      this.$api.oauth2Api.token.getTokenBySms(
        {
          account: this.form.phone,
          captcha: this.form.phoneCode,
          clientId: "PLAT_H5",
          clientSecret: "1234567",
          grantType: "SMS",
          phoneArea: this.form.phoneArea,
        },
        ({ data }) => {
          localStorage.setItem("lastPhoneNum", this.form.phone);
          loginCommon(data, this.$api);
          let routeRecordModule = this.RouteRecordModule;
          let beforeLoginPage = routeRecordModule.beforeLoginPage;
          if (beforeLoginPage) {
            if (routeRecordModule.goBackAfterLogin) {
              this.$router.go(-1);
            } else {
              this.$router.replace({
                path: beforeLoginPage.path,
                query: beforeLoginPage.query,
              });
            }
          } else {
            /**
             * 如果登录前没有路由记录，那么说明登录前不在当前单页应用中
             * 此时倒退就会来到应用外的页面，因此这里重定向到首页
             */
            this.$router.replace("/home");
          }
        }
      );
    }
  }
}
