
import { Component, Watch, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Icon,
  Image,
  Button,
  Checkbox,
  Form,
  Field,
  Dialog,
  Popup,
  Picker,
} from "vant";
@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Form.name]: Form,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
})
export default class PrivacyDialog extends Mixins(Mixin) {
  public openDialog = false;
  @Prop() private openPrivacy!: boolean;
  @Watch("openPrivacy")
  openPrivacys(): void {
    this.openDialog = this.openPrivacy;
  }
  // created() {

  // }
  goMynotice(name: string, type: string): void {
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
  //同意协议
  agreePrivacy(): void {
    this.openDialog = false;
    this.$emit("closePricay");
    this.$emit("oncheck");
  }
  //不同意协议
  disagreePrivacy(): void {
    this.openDialog = false;
    this.$emit("closePricay");
  }
}
